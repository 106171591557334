import { useEffect, useState } from 'react';
import { getExperiments } from 'src/checkout-frame/api/get-experiments';
import { AsyncRequestStatusType } from 'src/types/async-request-status';
import { Experiment, ExperimentParams } from 'src/types/experiment';

const TIMEOUT = 3000;

export const useExperiments = ({
  publicKey,
  merchantCode,
  currency,
}: ExperimentParams) => {
  const [experiments, setExperiments] = useState<{
    experiments: Experiment[];
    status: AsyncRequestStatusType;
  }>({ experiments: [], status: 'idle' });

  useEffect(() => {
    let isCanceled = false;

    const timeoutPromise = new Promise<null>((resolve) => {
      setTimeout(() => resolve(null), TIMEOUT);
    });

    (async () => {
      setExperiments((prev) => ({ ...prev, status: 'pending' }));

      const data = await Promise.race([
        getExperiments({
          ['merchant_id']: publicKey,
          ['merchant_code']: merchantCode,
          currency,
        }),
        timeoutPromise,
      ]);

      if (!isCanceled) {
        setExperiments({
          experiments: data ?? [],
          status: 'done',
        });
      }
    })();

    return () => {
      isCanceled = true;
    };
  }, [publicKey, merchantCode, currency]);

  return experiments;
};
