import { getEntityId } from 'src/checkout-frame/api/get-entity-id';
import { FrameEventType } from 'src/checkout-frame/events';
import { getCheckoutMessage } from 'src/checkout-frame/utils/get-checkout-message';
import { Experiment } from 'src/types/experiment';

export const getExperiments = async (
  additionalParams?: Record<string, string | number | undefined | null>
) => {
  const entityId = await getEntityId();

  const experiments = await getCheckoutMessage<Experiment[]>({
    type: FrameEventType.getExperiments,
    payload: {
      entityId,
      ...additionalParams,
    },
  });

  return experiments;
};
