const EVENT_PREFIX = 'tbb-co-event';

export const FrameEventType = {
  getEntityId: `${EVENT_PREFIX}-getEntityId`,
  getExperiments: `${EVENT_PREFIX}-getExperiments`,
  getMerchantId: `${EVENT_PREFIX}-getMerchantId`,
  getWidgetOffers: `${EVENT_PREFIX}-getWidgetOffers`,
} as const;

export type FrameEventType = typeof FrameEventType[keyof typeof FrameEventType];
