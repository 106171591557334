import React from 'react';
import { INSTALLMENTS_COUNT_DEFAULT } from 'src/context/store/app-provider';
import { getTypeOfContentPopup } from 'src/context/utils/get-type-of-popup-content';
import { Currency } from 'src/types/currency';
import { Lang, Locale } from 'src/types/lang-locale-type';
import { checkCurrency } from 'src/utils/check-currency';
import { checkInstallmentsCount } from 'src/utils/check-installments-count';
import { checkLang } from 'src/utils/check-lang';
import { checkLocale } from 'src/utils/check-locale';
import { checkPrice } from 'src/utils/check-price';
import { DataSetRecord } from 'src/utils/get-data-from-dataset';
import { getLangAndLocale } from 'src/utils/get-lang-and-locale';

export interface State {
  currencyRaw?: Currency;
  priceRaw?: number;
  lang: Lang;
  locale: Locale;
  currency: Currency;
  price: number;
  installmentsCount: number;
  tabbyInfo?: 'payLater';
  isBig?: boolean;
  isDark?: boolean;
  titles?: string[];
  isBold?: boolean;
  isMobileRounded?: boolean;
  isShortSplitIt?: boolean;
  typeOfContentPopup?: 'schedule' | 'calculator' | 'default';
}

export type Actions =
  | {
      type: 'updateStore';
      payload: Partial<State>;
    }
  | {
      type: 'updateScheduleType';
    }
  | {
      type: 'prepareAlshayaLimit5k';
    }
  | {
      type: 'updateFromDataSet';
      payload: DataSetRecord;
    }
  | {
      type: 'setTitles';
      payload: string[];
    }
  | {
      type: 'preparePayInFullPopup';
    }
  | {
      type: 'prepareSchedulePopup';
    };

export const storeReducer: React.Reducer<State, Actions> = (state, action) => {
  switch (action.type) {
    case 'preparePayInFullPopup': {
      return {
        ...state,
        isMobileRounded: true,
      };
    }
    case 'setTitles': {
      return {
        ...state,
        titles: action.payload,
      };
    }
    case 'updateFromDataSet': {
      const {
        tabbyLanguage,
        tabbyLocale,
        tabbyInstallmentsCount,
        tabbyInfo,
        tabbyPrice,
        tabbyCurrency,
      } = action.payload;

      const langAndLocale = getLangAndLocale(
        tabbyLanguage as Lang,
        tabbyLocale as Locale
      );

      return {
        ...state,
        installmentsCount: checkInstallmentsCount(INSTALLMENTS_COUNT_DEFAULT)(
          tabbyInstallmentsCount
        ),
        tabbyInfo,
        price: checkPrice(tabbyPrice),
        priceRaw: tabbyPrice,
        currency: checkCurrency(tabbyCurrency),
        currencyRaw: tabbyCurrency,
        ...(langAndLocale
          ? {
              lang: checkLang(langAndLocale.lang, {
                locale: langAndLocale.locale,
              }),
              locale: checkLocale(langAndLocale.locale, {
                lang: langAndLocale.lang,
              }),
            }
          : {}),
      };
    }
    case 'prepareAlshayaLimit5k': {
      return {
        ...state,
        isBig: true,
      };
    }
    case 'prepareSchedulePopup': {
      return {
        ...state,
        isBig: true,
        isDark: true,
        isBold: true,
        isMobileRounded: true,
        isShortSplitIt: true,
      };
    }
    case 'updateScheduleType': {
      const typeOfContentPopup = getTypeOfContentPopup(state);
      return {
        ...state,
        isBig: typeOfContentPopup === 'schedule',
        isDark: typeOfContentPopup === 'schedule',
        typeOfContentPopup,
      };
    }
    case 'updateStore': {
      return {
        ...state,
        ...action.payload,
        ...('installmentsCount' in action.payload
          ? {
              installmentsCount: checkInstallmentsCount(
                INSTALLMENTS_COUNT_DEFAULT
              )(action.payload.installmentsCount),
            }
          : {}),
        ...('lang' in action.payload
          ? {
              lang: checkLang(action.payload.lang, { locale: state.locale }),
            }
          : {}),
        ...('locale' in action.payload
          ? { locale: checkLocale(action.payload.locale, { lang: state.lang }) }
          : {}),
        ...('price' in action.payload
          ? {
              price: checkPrice(action.payload.price),
              priceRaw: action.payload.price,
            }
          : {}),
        ...('currency' in action.payload
          ? {
              currency: checkCurrency(action.payload.currency),
              currencyRaw: action.payload.currency,
            }
          : {}),
      };
    }

    default:
      return state;
  }
};
