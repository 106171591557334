import { FrameEventType } from 'src/checkout-frame/events';
import { getCheckoutMessage } from 'src/checkout-frame/utils/get-checkout-message';
import {
  WidgetDetailsRequestParams,
  WidgetOffers,
} from 'src/types/widget-offers';

export const getWidgetOffers = async ({
  publicKey,
  currency,
  merchantCode,
  amount,
}: WidgetDetailsRequestParams): Promise<{
  aid: string;
  offers: WidgetOffers;
}> => {
  try {
    const { aid, offers } = await getCheckoutMessage<{
      aid: string;
      offers: WidgetOffers;
    }>({
      type: FrameEventType.getWidgetOffers,
      payload: {
        publicKey,
        currency,
        merchantCode,
        amount,
      },
    });

    return { aid, offers };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return { offers: { installments: [] }, aid: '' };
  }
};
